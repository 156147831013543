import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import 'lib-flexible/flexible'
import 'element-ui/lib/theme-chalk/index.css'
import MessageBox from './components/MessageBox.vue'
import { Loading } from 'element-ui'

Vue.use(Loading.directive)
Vue.component('MessageBox', MessageBox)
Vue.config.productionTip = false

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
