import Vue from 'vue'
import VueRouter from 'vue-router'
import PcHome from '@/views/Pc/home.vue'
import PcLogin from '@/views/Pc/login.vue'
import Information from '@/views/Pc/information.vue'
import MobHome from '@/views/Mobile/mob_home.vue'
import MobLogin from '@/views/Mobile/mob_login.vue'
import MobInformation from '@/views/Mobile/mob_information.vue'

Vue.use(VueRouter)

// 判断设备类型的函数
const isMobile = () => {
  return window.innerWidth <= 768 // 根据屏幕宽度判断是否为移动设备
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: PcHome // 默认使用 PC 组件
  },
  {
    path: '/login',
    name: 'login',
    component: PcLogin // 默认使用 PC 组件
  },
  {
    path: '/information',
    name: 'InformationPage',
    component: Information // 默认使用 PC 组件
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 全局守卫
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token') // 获取 token
  const isUserAccessingProtectedRoute =
    to.name !== 'home' && to.name !== 'login'

  // 如果没有 token，限制访问非 home 和 login 的页面
  if (!token && isUserAccessingProtectedRoute) {
    return next({ name: 'home' }) // 重定向到 home
  }

  // 动态切换组件
  if (isMobile()) {
    if (to.name === 'home') {
      to.matched[0].components.default = MobHome
    } else if (to.name === 'login') {
      to.matched[0].components.default = MobLogin
    } else if (to.name === 'InformationPage') {
      to.matched[0].components.default = MobInformation
    }
  } else {
    // PC 端组件
    if (to.name === 'home') {
      to.matched[0].components.default = PcHome
    } else if (to.name === 'login') {
      to.matched[0].components.default = PcLogin
    } else if (to.name === 'InformationPage') {
      to.matched[0].components.default = Information
    }
  }

  next() // 继续导航
})

export default router
