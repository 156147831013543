export function gtagReportConversion(url) {
  // eslint-disable-next-line space-before-function-paren
  const callback = function () {
    if (typeof url !== 'undefined') {
      window.location = url
    }
  }

  // Trigger the Google Ads conversion event
  // eslint-disable-next-line no-undef
  gtag('event', 'conversion', {
    send_to: 'AW-16713195921/riQqCJGfgtkZEJHDvKE-', // Replace with your actual conversion ID
    value: 1.0, // Conversion value (adjust as needed)
    currency: 'USD', // Currency (adjust as needed)
    event_callback: callback
  })

  return false
}
