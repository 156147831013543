<template>
  <transition name="fade">
    <div v-if="visible" class="message-box" :class="type">
      <span>{{ message }}</span>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      message: '',
      type: 'info' // 预设类型为 info
    }
  },
  methods: {
    show(msg, type = 'info') {
      this.message = msg
      this.type = type
      this.visible = true

      // 自动关闭提示框
      setTimeout(this.close, 5000)
    },
    close() {
      this.visible = false
    }
  }
}
</script>

<style scoped>
.message-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* 居中 */
  padding: 20px 30px; /* 内边距 */
  border-radius: 10px; /* 圆角 */
  font-size: 16px; /* 字体大小 */
  color: #fff;
  z-index: 9999;
  text-align: center;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.5); /* 更明显的阴影效果 */
  transition:
    transform 0.3s ease,
    opacity 0.3s ease; /* 添加平滑过渡 */
  opacity: 0.9; /* 轻微透明效果 */
}

.message-box.info {
  background: rgba(0, 123, 255, 0.9); /* 更柔和的蓝色 */
  border: 2px solid rgba(0, 123, 255, 1); /* 边框 */
}

.message-box.success {
  background: rgba(40, 167, 69, 0.9); /* 更柔和的绿色 */
  border: 2px solid rgba(40, 167, 69, 1); /* 边框 */
}

.message-box.error {
  background: rgba(220, 53, 69, 0.9); /* 更柔和的红色 */
  border: 2px solid rgba(220, 53, 69, 1); /* 边框 */
}

.fade-enter-active,
.fade-leave-active {
  transition:
    opacity 0.5s,
    transform 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateY(-20px); /* 进入时向上移动 */
}
</style>
