<template>
  <div class="main">
    <MessageBox ref="messageBox" />
    <div class="header">
      <div
        @click="
          () => {
            $router.push('/')
          }
        "
      >
        <img src="../../assets/logo.png" alt="Logo" />
      </div>
    </div>
    <div class="content">
      <div class="content_box">
        <el-form
          ref="form"
          label-position="top"
          :hide-required-asterisk="true"
          :model="form"
          :rules="rules"
          inline-message
          label-width="80px"
          @submit.native.prevent="handleSubmit"
        >
          <el-form-item
            v-for="(field, index) in formFields"
            :key="index"
            :prop="field.model"
          >
            <template slot="label">
              <p class="from_label">{{ field.label }} <span>*</span></p>
            </template>
            <el-input v-model="form[field.model]" />
          </el-form-item>

          <el-form-item prop="approximateIncome">
            <p class="from_label">Monthly salary range <span>*</span></p>
            <el-select
              v-model="form.approximateIncome"
              class="select"
              placeholder="Select"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>

          <el-form-item prop="avatar">
            <p class="from_label">Upload Avatar</p>
            <el-upload
              v-loading="isLoading"
              action="#"
              class="upload"
              list-type="picture-card"
              :auto-upload="false"
              :on-change="handleAvatarChange"
              :before-upload="beforeAvatarUpload"
              :file-list="uploadFileList"
              :on-remove="handleRemove"
            >
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{ file }">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                />
                <span class="el-upload-list__item-actions">
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
          </el-form-item>

          <div class="login_button_box">
            <el-button
              :loading="isLoading"
              type="primary"
              class="login_button_box_button"
              @click="handleSubmit"
            >
              Submit
            </el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/services/http'
import API_ENDPOINTS from '@/services/api'
import {
  Input,
  Form,
  FormItem,
  Select,
  Option,
  Upload,
  Button
} from 'element-ui'

export default {
  name: 'InformationPage',
  components: {
    ElInput: Input,
    ElForm: Form,
    ElFormItem: FormItem,
    ElSelect: Select,
    ElOption: Option,
    ElUpload: Upload,
    ElButton: Button
  },
  data() {
    return {
      isLoading: false,
      form: {
        name: '',
        age: '',
        area: '',
        height: '',
        weight: '',
        profession: '',
        approximateIncome: '',
        personalInformation: '',
        profile: '',
        hobbies: ''
      },
      uploadLoading: true,
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      options: [
        { value: '1000-2500', label: '1000-2500' },
        { value: '2500 - 5000', label: '2500 - 5000' },
        { value: '5000 - 7000', label: '5000 - 7000' },
        { value: '7000 - 10000', label: '7000 - 10000' }
      ],
      formFields: [
        { label: 'Name', model: 'name' },
        { label: 'Age', model: 'age' },
        { label: 'Area', model: 'area' },
        { label: 'Height', model: 'height' },
        { label: 'Weight', model: 'weight' },
        { label: 'Profession', model: 'profession' },
        { label: 'Introduce yourself', model: 'personalInformation' },
        { label: 'Profile', model: 'profile' },
        { label: 'Hobbies', model: 'hobbies' }
      ],
      uploadFileList: [], // 用于存储上传的文件
      rules: {
        name: [
          { required: true, message: 'Please input your name', trigger: 'blur' }
        ],
        age: [
          { required: true, message: 'Please input your age', trigger: 'blur' },
          {
            type: 'string',
            message: 'Age must be a number',
            trigger: ['blur', 'change']
          },
          {
            min: 1,
            max: 120,
            message: 'Age must be between 1 and 120',
            trigger: 'change'
          }
        ],
        area: [
          { required: true, message: 'Please input your area', trigger: 'blur' }
        ],
        height: [
          {
            required: true,
            message: 'Please input your height',
            trigger: 'blur'
          }
        ],
        weight: [
          {
            required: true,
            message: 'Please input your weight',
            trigger: 'blur'
          }
        ],
        profession: [
          {
            required: true,
            message: 'Please input your profession',
            trigger: 'blur'
          }
        ],
        approximateIncome: [
          {
            required: true,
            message: 'Please select your income range',
            trigger: 'change'
          }
        ],
        personalInformation: [
          {
            required: true,
            message: 'Please introduce yourself',
            trigger: 'blur'
          }
        ],
        profile: [
          {
            required: true,
            message: 'Please fill your profile',
            trigger: 'blur'
          }
        ],
        hobbies: [
          {
            required: true,
            message: 'Please fill in your hobbies',
            trigger: 'blur'
          }
        ]
        // avatar: [
        //   {
        //     required: true,
        //     message: 'Please upload your avatar',
        //     trigger: 'change'
        //   }
        // ]
      }
    }
  },
  methods: {
    handleRemove(file) {
      const index = this.uploadFileList.indexOf(file)
      if (index !== -1) {
        this.uploadFileList.splice(index, 1)
      }
    },
    handleAvatarChange(file, fileList) {
      this.isLoading = true
      http
        .uploadImage(API_ENDPOINTS.USERS.UPLOAD, file.raw)
        .then((res) => {
          const { code, msg } = res
          if (code === 200) {
            const newFile = fileList
            const index = newFile.indexOf(file)
            if (index !== -1) {
              newFile[index].url = msg
            }
            this.uploadFileList = newFile

            console.log(this.uploadFileList)
          } else {
            this.$refs.messageBox.show(msg, 'error')
          }
        })
        .catch((error) => {
          this.$refs.messageBox.show(error, 'error')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    beforeAvatarUpload(file) {
      const isImage = file.type.startsWith('image/')
      const isLt2M = file.size / 1024 / 1024 < 2 // 限制上传文件大小为2MB
      if (!isImage) {
        this.$message.error('Upload avatar image only!')
      }
      if (!isLt2M) {
        this.$message.error('Avatar size cannot exceed 2MB!')
      }

      return isImage && isLt2M // 返回验证结果
    },
    handleSubmit() {
      const usaerInfo = JSON.parse(localStorage.getItem('userinfo'))
      const { form, uploadFileList } = this
      form.userId = usaerInfo.id
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (uploadFileList.length > 0) {
            var image1 = uploadFileList.map((item) => {
              return item.url
            })
            form.image1 = JSON.stringify(image1)
          }
          this.isLoading = true
          http
            .post(API_ENDPOINTS.USERS.SAVEINFO, form)
            .then((res) => {
              const { code, msg } = res
              if (code === 200) {
                this.$refs.messageBox.show(
                  'Registration successful. You are being connected to customer service. Please wait patiently.',
                  'success'
                )
                setTimeout(() => {
                  window.location.href =
                    'https://chat.ssrchat.com/service/g9n8ic'
                }, 2000)
              } else {
                this.$refs.messageBox.show(msg, 'error')
              }
            })
            .catch((error) => {
              this.$refs.messageBox.show(error, 'error')
            })
            .finally(() => {
              this.isLoading = false
            })

          // 这里可以执行提交表单的逻辑，例如发送到服务器
        } else {
          console.log('error submit!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    height: 56px;
    width: 100%;
    display: flex;
    align-items: center;
    background: #151515;
    position: fixed;
    z-index: 99;
    box-sizing: border-box;
    img {
      width: 145px;
      height: 24px;
      margin-left: 20px;
    }
  }

  .content {
    flex: 1;
    background: url(../../assets/information_bg.png) no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0 16px 0;
    .content_box {
      width: 343px;
      // background: #ffffff;
      padding: 53px 0 0 0;
      box-sizing: border-box;

      border-radius: 11px;

      .el-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        // display: flex;
        // flex-direction: row;
        // justify-content: space-between;
        // flex-wrap: wrap;
      }
      .login_button_box {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 8px;
        .login_button_box_button {
          width: 300px;
          height: 50px;
          border: none;
          background: linear-gradient(90deg, #fb2860 0%, #fc4840 100%);
          border-radius: 22px;
        }
      }

      .el-form-item {
        width: 300px;
        background: white;
        border: 1px solid;
        border-color: #878787;
        border-radius: 8px;
        padding: 0 7px 8px 8px;
        box-sizing: border-box;
        .el-form--label-top .el-form-item__label {
          padding: 0;
        }
        .from_label {
          font-family: DIN Pro;
          font-weight: 700;
          color: #1a1414;
          margin: 0;
          padding: 0;
          font-size: 14px;

          span {
            color: red;
          }
        }

        .select {
          width: 100%;
        }
      }
    }
  }
}
</style>
