<template>
  <div class="mob_ogin">
    <MessageBox ref="messageBox" />
    <div class="main">
      <img src="../../assets/logo.png" class="main_logo" alt="" />
      <div class="main_login_box">
        <div
          class="go_back clickable"
          @click="
            () => {
              $router.push('/')
            }
          "
        >
          <img src="../../assets/goback_icon.png" class="clickable" alt="" />
        </div>
        <img
          src="../../assets/pc_login_welcome.png"
          class="main_login_box_logo"
          alt=""
        />
        <el-tabs
          v-model="activeName"
          class="main_login_box_tab"
          @tab-click="handleClick"
        >
          <el-tab-pane label="Register" name="register"></el-tab-pane>
          <el-tab-pane label="Log in" name="login"></el-tab-pane>
        </el-tabs>
        <div class="main_login_box_tab_contents">
          <el-form
            ref="formRef"
            :model="ruleForm"
            :rules="rules"
            label-width="0px"
            class="demo-ruleForm"
          >
            <el-form-item prop="imGender">
              <gender-radio
                v-if="activeName === 'register'"
                v-model="ruleForm.imGender"
              ></gender-radio>
            </el-form-item>
            <el-form-item label="" prop="gmail">
              <el-input
                v-model="ruleForm.gmail"
                placeholder="Please enter your email address"
                class="login_input"
              >
                <template slot="prefix">
                  <div class="login_input_username">
                    <img src="../../assets/username_icon.png" alt="" />
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="" prop="password">
              <el-input
                v-model="ruleForm.password"
                type="password"
                placeholder="Please enter your password"
                class="login_input"
              >
                <template slot="prefix">
                  <div class="login_input_username">
                    <img src="../../assets/password_icon.png" alt="" />
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item>
              <div class="login_button_box">
                <el-button
                  :loading="isLoading"
                  type="primary"
                  class="login_button_box_button"
                  @click="submit()"
                >
                  {{ activeName === 'register' ? 'Register' : 'Login' }}
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/services/http'
import API_ENDPOINTS from '@/services/api'
import { Tabs, TabPane, Input, Form, FormItem, Button } from 'element-ui'
import GenderRadio from './component/gender_radio.vue'

export default {
  name: 'PcLogin',
  components: {
    ElTabs: Tabs,
    ElTabPane: TabPane,
    ElInput: Input,
    ElForm: Form,
    ElFormItem: FormItem,
    ElButton: Button,
    GenderRadio
  },
  data() {
    return {
      activeName: 'login',
      ruleForm: {
        gmail: '',
        password: '',
        imGender: '男'
      },
      isLoading: false,
      token: null,
      rules: {
        gmail: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          {
            type: 'email',
            message: '邮箱地址格式不正确',
            trigger: ['blur', 'change']
          }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 8, message: '密码长度至少为 8 个字符', trigger: 'blur' },
          { validator: this.validatePassword, trigger: 'blur' }
        ]
      }
    }
  },

  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    },
    submit() {
      const { ruleForm } = this
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.isLoading = true
          const endpoint =
            this.activeName === 'register'
              ? API_ENDPOINTS.USERS.REGISTER
              : API_ENDPOINTS.USERS.LOGIN // 根据选项选择不同的 API 端点
          http
            .post(endpoint, ruleForm) // 修改为 post 方法
            .then((res) => {
              if (this.activeName === 'register') {
                this.$refs.messageBox.show('register successful', 'success')
                this.activeName = 'login'
                return
              }
              const { code, msg, data = {} } = res
              if (code === 200) {
                localStorage.setItem('token', data.token)
                localStorage.setItem('userinfo', JSON.stringify(data))
                this.$refs.messageBox.show('login successful', 'success')
                const username = localStorage.getItem('name')
                if (username) {
                  window.location.href =
                    'https://chat.ssrchat.com/service/g9n8ic'
                } else {
                  this.$router.push('/information')
                }
              } else {
                this.$refs.messageBox.show(msg, 'error')
              }
              // 可以在这里处理成功后的逻辑，如重定向
            })
            .catch((error) => {
              this.$refs.messageBox.show(error, 'error')
              // 可以在这里处理失败后的逻辑，比如提示用户
            })
            .finally(() => {
              this.isLoading = false
            })
        } else {
          console.log('表单验证失败!')
        }
      })
    },
    validatePassword(rule, value, callback) {
      // 自定义密码验证逻辑
      if (value && value.length < 8) {
        callback(new Error('密码长度至少为 8 个字符'))
      } else {
        callback()
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import url(./mob_login.less);
</style>
