// http.js
import axios from 'axios'

// 创建 axios 实例
const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || '', // 这里可以保持为空，假设你在 vue.config.js 中设置了代理
  timeout: 10000 // 请求超时设置
})
// 请求拦截器
http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token') // 假设你将 token 存储在 localStorage
    if (token) {
      config.headers['Authorization'] = `${token}`
    }
    console.log('Request Config:', config)
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
http.interceptors.response.use(
  (response) => {
    return response.data // 仅返回数据部分
  },
  (error) => {
    if (error.response) {
      console.error(
        `Error ${error.response.status}: ${error.response.data.message}`
      )
      if (error.response.status === 401) {
        // 清除 token 或其他身份信息
        localStorage.removeItem('token')
        this.$router.push('/login') // 跳转到登录页面
      }
    } else if (error.request) {
      console.error('No response received:', error.request)
    } else {
      console.error('Error:', error)
    }
    return Promise.reject(error)
  }
)

// 创建一个通用的 GET 请求方法
const get = (url, params = {}) => {
  return http.get(url, { params })
}

// 创建一个通用的 POST 请求方法
const post = (url, data = {}) => {
  return http.post(url, data)
}

const uploadImage = (url, file) => {
  console.log('file', file)
  const formData = new FormData()
  formData.append('file', file) // 假设后端需要的字段名为 'file'
  console.log('formData', formData)

  return http.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 导出封装的 axios 实例及请求方法
export default {
  get,
  post,
  uploadImage
}
