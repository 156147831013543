<template>
  <div class="pc_main no_rem">
    <img
      ref="sourceElement"
      class="pc_main_bg"
      src="../../assets/pc_home_bg.png"
      alt=""
    />
    <video muted autoplay loop :src="videoSrc" class="pc_main_bg"></video>
    <div class="pc_header">
      <img src="../../assets/logo.png" class="pc_header_left_img" alt="" />

      <!-- <kinesis-container>
        <div
          v-if="!token"
          class="animate__bounce pc_login_button clickable"
          @click="
            () => {
              $router.push('/login')
            }
          "
        >
          <p>Log in</p>
          <vue-kinesis>
            <kinesis-element :strength="4" type="scale">
              <img src="../../assets/login_button.png" alt="" />
            </kinesis-element>
          </vue-kinesis>
        </div>
      </kinesis-container> -->
    </div>
    <div ref="targetElement" class="pc_center">
      <div class="pc_center_content">
        <p class="pc_center_content_title">Find your ideal partner</p>
        <p class="pc_center_content_detail">
          Join us to enjoy personalized matching services and start a new social
          experience!
        </p>
      </div>
      <div class="pc_center_button clickable" @click="handelelogo">
        Searching for true love
      </div>
    </div>
    <div class="pc_footer">
      <img
        class="pc_footer_left"
        src="../../assets/pc_home_footer_left.png"
        alt=""
      />
      <!-- <img
        class="pc_footer_right"
        src="../../assets/pc_home_footer_right.png"
        alt=""
      /> -->
      <div class="pc_footer_right">
        <div
          v-for="(item, index) in contentRight"
          :key="index"
          class="pc_footer_right_item"
        >
          <div class="pc_footer_right_item_header">
            <div class="pc_footer_right_item_header_left">
              <p>{{ item.title }}</p>
            </div>
            <img
              class="pc_footer_right_item_header_right"
              src="../../assets/pc_header_item_detail_icon.png"
              alt=""
            />
          </div>
          <div class="pc_footer_right_content">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/services/http'
import API_ENDPOINTS from '@/services/api'
import { gtagReportConversion } from '../../../utils/gtag'
import videoSrc from '@/assets/web_video.mov'
export default {
  name: 'PcHome',
  components: {},
  data() {
    return {
      videoSrc: videoSrc,
      token: null,
      usaerInfo: null,
      name: null,
      contentRight: [
        {
          title: 'Amy & John',
          content:
            '🎉 Amy and John found each other through our human matching service!Amy and John chose the manual matching service when using our platform. Our matching experts create precise matches based on their interests and personality traits. After several in-depth exchanges, they discovered a deep compatibility with each other and eventually established a happy long-term relationship. This is a real example of how our human matching service helps users find their ideal partner!'
        },
        {
          title: 'Lisa & Mark',
          content:
            '💖 Lisa and Mark’s success story!Lisa and Mark received personalized match recommendations by contacting our human matching team. We make precise matches based on their interests and lifestyle. As a result, they felt a perfect fit after meeting several times and decided to move toward a future together. This is a testament to the effectiveness and success of our human matching service!'
        },
        {
          title: 'Sophia & Daniel',
          content:
            '🚀 Sophia and Daniel’s success story!Sophia and Daniel decided to try a human matching service while using our platform. Our matching experts make personalized match recommendations based on their needs and expectations. After several interactions, they found that they were a good match for each other and established a solid relationship. This shows the power of our human matching service!'
        }
      ]
    }
  },
  created() {
    this.token = localStorage.getItem('token')
    this.usaerInfo = localStorage.getItem('userinfo')
    if (this.usaerInfo) {
      const userinfos = JSON.parse(this.usaerInfo)
      const { id } = userinfos

      http.post(API_ENDPOINTS.USERS.GETUSERINFO, { userId: id }).then((res) => {
        const { code, data } = res
        if (code === 200) {
          const { name = '' } = data
          localStorage.setItem('name', name)
          this.name = name
        }
      })
    }
  },
  mounted() {
    const sourceElement = this.$refs.sourceElement
    sourceElement.onload = () => this.adjustTargetHeight()
  },

  methods: {
    handelelogo() {
      // const { token, name } = this
      // if (token && !name) {
      //   this.$router.push('/information')
      // } else if (token && name) {
      gtagReportConversion('https://chat.ssrchat.com/service/g9n8ic')

      // } else {
      //   this.$router.push('/login')
      // }
    },
    adjustTargetHeight() {
      const sourceElement = this.$refs.sourceElement
      const targetElement = this.$refs.targetElement

      if (sourceElement && targetElement) {
        const height = sourceElement.offsetHeight || 0 // 防止 undefined
        console.log('Source height:', height) // 打印高度
        targetElement.style.height = `${height}px`
      } else {
        console.error('无法找到元素的引用')
      }
    }
  }
}
</script>

<style lang="less" scoped>
/* postcss-pxtorem: disable */
.pc_main {
  position: relative;
  overflow: hidden;
  .pc_main_bg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    // width: 100%;
    width: 100%;
    // height: 100vh;
    height: auto; /* 保持比例 */
  }

  .pc_header {
    position: fixed;
    box-sizing: border-box;
    width: 100%;
    height: 78px;
    background: #151515;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 110px;
    box-sizing: border-box;
    .pc_header_left_img {
      width: 269px;
      height: auto;
    }

    .pc_login_button {
      width: 148px;
      height: 56px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 36px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 5px 0 20px;

      p {
        color: #ffffff;
        font-family: PingFang SC;
        font-size: 20px;
        line-height: 30px;
        margin: 0;
      }

      img {
        width: 40px;
        height: 40px;
      }
    }
  }

  .pc_center {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .pc_center_content {
      margin-top: 290px;

      .pc_center_content_title {
        text-shadow: 0 9px 4.3px rgba(0, 0, 0, 0.25);
        font-family: DINCond-BlackAlternate;
        font-weight: 500;
        color: #ffffff;
        font-size: 96px;
        letter-spacing: 1.5%;
        line-height: normal;
        margin: 0;
        text-align: center;
      }

      .pc_center_content_detail {
        width: 1250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: DIN Pro;
        font-weight: 300;
        color: #ffffff;
        font-size: 32px;
        letter-spacing: 1.5%;
        margin: 20px 0 0 0;
      }
    }
    /* postcss-pxtorem: disable */
    .pc_center_button {
      margin-top: 60px;
      width: 280px;
      height: 60px;
      background: linear-gradient(270deg, #fb483e 0%, #fa2760 100%);
      border-radius: 30px;
      color: #ffffff;
      font-size: 20px;
      line-height: 60px;
      text-align: center;
      border: none;
      cursor: pointer;
    }
  }
  /* postcss-pxtorem: disable */
  .pc_footer {
    margin-top: 100px;
    padding: 0 178px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pc_footer_left {
      width: 55%;
      height: auto;
    }

    .pc_footer_right {
      display: flex;
      width: 430px;
      flex-direction: column;
      align-items: center;
      .pc_footer_right_item {
        margin-bottom: 35px;
        border: 1px solid;
        border-color: #1b2026;
        border-radius: 11px;
        padding: 0 17px 17px 17px;
        .pc_footer_right_item_header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .pc_footer_right_item_header_left {
            width: 300px;
            border-bottom: 1px solid rgba(18, 20, 23, 0.8);
            height: 57px;
            line-height: 57px;
            p {
              color: #121417;
              font-size: 18px;
              font-weight: 700;
              margin: 0;
              padding: 0;
            }
          }

          img {
            width: 42px;
            height: 30px;
          }
        }
        .pc_footer_right_content {
          margin-top: 18px;
          font-family: DIN Pro;
          color: #121417;
          font-size: 12px;
          letter-spacing: 1px;
          line-height: 18px;
        }
      }
    }
  }
}
</style>
