<template>
  <div class="gender_radio">
    <p class="title">I am</p>
    <div class="img">
      <div @click="handleSelect('男')">
        <img
          :src="
            Selected === '男'
              ? require('../../../assets/man_sel.png')
              : require('../../../assets/man.png')
          "
          alt="男"
        />
      </div>
      <div @click="handleSelect('女')">
        <img
          :src="
            Selected === '女'
              ? require('../../../assets/woman_sel.png')
              : require('../../../assets/woman.png')
          "
          alt="女"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String
  },
  data() {
    return {
      Selected: this.value // 默认选中的性别
    }
  },
  methods: {
    handleSelect(val) {
      this.Selected = val // 更新选中的性别
      this.$emit('input', val)
    }
  }
}
</script>

<style lang="less" scoped>
.gender_radio {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin: 0;
    padding: 0;
    color: #000000;
    font-size: 14px;
  }

  .img {
    display: flex; // 使用 flexbox 布局
    width: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    img {
      width: 32px;
      cursor: pointer; // 鼠标悬停时显示为可点击
    }
  }
}
</style>
