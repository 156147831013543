const isProduction = process.env.NODE_ENV === 'production'

const API_PREFIX = isProduction ? '' : '/api' // 线上的不需要 /api，开发环境需要

const API_ENDPOINTS = {
  USERS: {
    LOGIN: `${API_PREFIX}/user/login`,
    REGISTER: `${API_PREFIX}/user/register`,
    UPLOAD: `${API_PREFIX}/userInfo/upload`,
    SAVEINFO: `${API_PREFIX}/userInfo/saveUserInfo`,
    GETUSERINFO: `${API_PREFIX}/userInfo/getUserInfo`
  }
  // 添加其他 API 路径
}

export default API_ENDPOINTS
